<template>
    <div class="container-fluid pt-5 bg-primary hero-header" id="inicio_diseno">
        <br>
    </div>

    <InicioDiseno />

    <!-- Case Start -->
    <div class="container-fluid bg-light pb-5" id="plan">
        <div class="container pt-5">
            <div class="mx-auto text-center wow fadeIn" data-wow-delay="0.1s" style="max-width: 500px;">
                <div class="btn btn-sm border rounded-pill text-primary px-3 mb-3">Nuestros Planes de Diseño</div>
                <h1 class="mb-4  wow fadeIn" data-wow-delay="0.1s">Elige el plan perfecto para tu negocio</h1>
            </div>
            <div class="row g-4 my-5 text-center">
                    <div class="col-sm-12 col-md-4 col-lg-4">
                        <div class="card mb-4 rounded-3 shadow-sm wow fadeIn" data-wow-delay="0.3s">
                            <div class="card-header py-3">
                                <h4 class="my-0 fw-normal">Esencial</h4>
                            </div>
                            <div class="card-body ">
                                <h2 class="card-title pricing-card-title">US$ 250.00</h2>
                                <h6 class="text-center ">(Pago Único)</h6>
                                <ul class="list-unstyled mt-3 mb-4 text-start">
                                    <li><i class="fas fa-check-circle text-success"></i> Diseño Wordpress (Template
                                        Divi).</li>
                                    <li><i class="fas fa-check-circle text-success"></i> 01 Licencia DIVI.</li>
                                    <li><i class="fas fa-check-circle text-success"></i> 5 Paginas.</li>
                                    <li><i class="fas fa-check-circle text-success"></i> 3 Secciones.</li>
                                    <li><i class="fas fa-check-circle text-success"></i> Formulario de contacto.</li>
                                    <li><i class="fas fa-check-circle text-success"></i> 300 GB SSD almacenamiento.</li>
                                    <li><i class="fas fa-check-circle text-success"></i> Correos electrónicos (Ilimitados).</li>
                                    <li><i class="fas fa-check-circle text-success"></i> Certificado SSL gratis.</li>
                                    <li><i class="fas fa-check-circle text-success"></i> Base de datos (Ilimitadas).</li>
                                    <li><i class="fas fa-check-circle text-success"></i> Sub dominios (Ilimitados).</li>
                                    <li>&nbsp;</li>
                                    <li>&nbsp;</li>
                                    <li>&nbsp;</li>
                                    <li><i class="fas fa-bullseye text-danger"></i><strong> Nota: </strong>Después del Primer Año el cliente paga el valor del hosting.</li>
                                </ul>

                                <button type="button" class="w-100 btn btn-lg btn-primary" data-bs-toggle="modal"
                                    data-bs-target="#general"
                                    @click="plan = 'Esencial'; titulo_modal = 'Contratar plan Esencial';">Comenzar
                                    ahora</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-4 col-lg-4">
                        <div class="card mb-4 rounded-3  shadow border border-success wow fadeIn" data-wow-delay="0.3s">
                            <div class="card-header py-3 bg-primary">
                                <h4 class="my-0 fw-normal text-white">Avanzado</h4>
                            </div>
                            <div class="card-body">
                                <h2 class="card-title pricing-card-title">US$ 350.00</h2>
                                <h6 class="text-center ">(Pago Único)</h6>
                                <ul class="list-unstyled mt-3 mb-4 text-start">
                                    <li><i class="fas fa-check-circle text-success"></i> Diseño Wordpress (Template
                                        Divi).</li>
                                    <li><i class="fas fa-check-circle text-success"></i> 01 Licencia DIVI.</li>
                                    <li><i class="fas fa-check-circle text-success"></i> 8 Paginas.</li>
                                    <li><i class="fas fa-check-circle text-success"></i> 4 Secciones.</li>
                                    <li><i class="fas fa-check-circle text-success"></i> Formulario de contacto.</li>
                                    <li><i class="fas fa-check-circle text-success"></i> 300 GB SSD almacenamiento.</li>
                                    <li><i class="fas fa-check-circle text-success"></i> Correos electrónicos (Ilimitados).</li>
                                    <li><i class="fas fa-check-circle text-success"></i> Certificado SSL gratis.</li>
                                    <li><i class="fas fa-check-circle text-success"></i> Base de datos (Ilimitadas).</li>
                                    <li><i class="fas fa-check-circle text-success"></i> Sub dominios (Ilimitados).</li>
                                    <li><i class="fas fa-check-circle text-success"></i> 1 actualización de contenido
                                        anual.</li>
                                    <li>&nbsp;</li>
                                    <li>&nbsp;</li>
                                    <li><i class="fas fa-bullseye text-danger"></i><strong> Nota: </strong>Después del Primer Año el cliente paga el valor del hosting.</li>

                                </ul>
                                <button type="button" class="w-100 btn btn-lg btn-primary" data-bs-toggle="modal"
                                    data-bs-target="#general"
                                    @click="plan = 'Avanzado'; titulo_modal = 'Contratar plan Avanzado';">Comenzar
                                    ahora</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-4 col-lg-4">
                        <div class="card mb-4 rounded-3 shadow-sm wow fadeIn" data-wow-delay="0.3s">
                            <div class="card-header py-3 text-white">
                                <h4 class="my-0 fw-normal">Premium</h4>

                            </div>
                            <div class="card-body">
                                <h2 class="card-title pricing-card-title">US$ 550.00</h2>
                                <h6 class="text-center ">(Pago Único)</h6>
                                <ul class="list-unstyled mt-3 mb-4 text-start">
                                    <li><i class="fas fa-check-circle text-success"></i> Diseño Wordpress (Template
                                        Divi).</li>
                                    <li><i class="fas fa-check-circle text-success"></i> 01 Licencia DIVI.</li>
                                    <li><i class="fas fa-check-circle text-success"></i> 10 Paginas.</li>
                                    <li><i class="fas fa-check-circle text-success"></i> 6 Secciones.</li>
                                    <li><i class="fas fa-check-circle text-success"></i> Formulario de contacto.</li>
                                    <li><i class="fas fa-check-circle text-success"></i> 300 GB SSD almacenamiento.</li>
                                    <li><i class="fas fa-check-circle text-success"></i> Correos electrónicos (Ilimitados).</li>
                                    <li><i class="fas fa-check-circle text-success"></i> Certificado SSL gratis.</li>
                                    <li><i class="fas fa-check-circle text-success"></i> Base de datos (Ilimitadas).</li>
                                    <li><i class="fas fa-check-circle text-success"></i> Sub dominios (Ilimitados).</li>
                                    <li><i class="fas fa-check-circle text-success"></i> 3 actualizaciones de contenido
                                        anual.</li>
                                    <li><i class="fas fa-check-circle text-success"></i> Chat multiagente con el
                                        cliente.</li>                                    
                                    <li>&nbsp;</li>
                                    <li><i class="fas fa-bullseye text-danger"></i><strong> Nota: </strong>Después del Primer Año el cliente paga el valor del hosting.</li>
                                </ul>
                                <button type="button" class="w-100 btn btn-lg btn-primary" data-bs-toggle="modal"
                                    data-bs-target="#general"
                                    @click="plan = 'Premium'; titulo_modal = 'Contratar plan Premium';">Comenzar
                                    ahora</button>
                            </div>
                    </div>
                </div>

                <div class="d-none d-md-block">
                <h2 class="display-6 text-center mb-4 wow fadeIn" data-wow-delay="0.1s">Comparador de Características
                </h2>

                <div class="table-responsive">
                    <table class="table text-center">
                        <thead>
                            <tr>
                                <th style="width: 34%;"></th>
                                <th style="width: 22%;">Esencial</th>
                                <th style="width: 22%;">Avanzado</th>
                                <th style="width: 22%;">Premium</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row" class="text-start">Diseño inicial gratis</th>
                                <td><i class="bi bi-check-circle text-success"></i></td>
                                <td><i class="bi bi-check-circle text-success"></i></td>
                                <td><i class="bi bi-check-circle text-success"></i></td>
                            </tr>
                            <tr>
                                <th scope="row" class="text-start">Almacenamiento</th>
                                <td><i class="bi bi-check-circle text-success"></i> 300 GB SSD</td>
                                <td><i class="bi bi-check-circle text-success"></i> 300 GB SSD </td>
                                <td><i class="bi bi-check-circle text-success"></i> 300 GB SSD</td>
                            </tr>
                            <tr>
                                <th scope="row" class="text-start">Correo electrónico</th>
                                <td><i class="bi bi-check-circle text-success"></i> Ilimitado</td>
                                <td><i class="bi bi-check-circle text-success"></i> Ilimitado</td>
                                <td><i class="bi bi-check-circle text-success"></i> Ilimitado</td>
                            </tr>
                            <tr>
                                <th scope="row" class="text-start">Certificado SSL gratis</th>
                                <td><i class="bi bi-check-circle text-success"></i></td>
                                <td><i class="bi bi-check-circle text-success"></i></td>
                                <td><i class="bi bi-check-circle text-success"></i></td>
                            </tr>
                            <tr>
                                <th scope="row" class="text-start">Licencia Divi</th>
                                <td><i class="bi bi-check-circle text-success"></i></td>
                                <td><i class="bi bi-check-circle text-success"></i></td>
                                <td><i class="bi bi-check-circle text-success"></i></td>
                            </tr>
                            <tr>
                                <th scope="row" class="text-start">Páginas</th>
                                <td><i class="bi bi-check-circle text-success"></i> 5</td>
                                <td><i class="bi bi-check-circle text-success"></i> 8</td>
                                <td><i class="bi bi-check-circle text-success"></i> 10</td>
                            </tr>
                            <tr>
                                <th scope="row" class="text-start">Secciones</th>
                                <td><i class="bi bi-check-circle text-success"></i> 3</td>
                                <td><i class="bi bi-check-circle text-success"></i> 4</td>
                                <td><i class="bi bi-check-circle text-success"></i> 6</td>
                            </tr>
                            <tr>
                                <th scope="row" class="text-start">Formulario de contacto</th>
                                <td><i class="bi bi-check-circle text-success"></i></td>
                                <td><i class="bi bi-check-circle text-success"></i></td>
                                <td><i class="bi bi-check-circle text-success"></i></td>
                            </tr>
                            <tr>
                                <th scope="row" class="text-start">Bases de datos</th>
                                <td><i class="bi bi-check-circle text-success"></i> Ilimitado</td>
                                <td><i class="bi bi-check-circle text-success"></i> Ilimitado</td>
                                <td><i class="bi bi-check-circle text-success"></i> Ilimitado</td>
                            </tr>
                            <tr>
                                <th scope="row" class="text-start">Sub dominios</th>
                                <td><i class="bi bi-check-circle text-success"></i> Ilimitados</td>
                                <td><i class="bi bi-check-circle text-success"></i> Ilimitados</td>
                                <td><i class="bi bi-check-circle text-success"></i> Ilimitados</td>
                            </tr>
                            <tr>
                                <th scope="row" class="text-start">Chat multiagente</th>
                                <td></td>
                                <td><i class="bi bi-check-circle text-success"></i></td>
                                <td><i class="bi bi-check-circle text-success"></i></td>
                            </tr>
                            <tr>
                                <th scope="row" class="text-start">Actualizaciones de contenido anual</th>
                                <td></td>
                                <td><i class="bi bi-check-circle text-success"></i> 1</td>
                                <td><i class="bi bi-check-circle text-success"></i> 3</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            </div>
        </div>

        <!-- licencias divi -->

        <div class="container-fluid pb-5 mt-5" id="licencias">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-12 wow fadeIn" data-wow-delay="0.5s">
                    <div class="mx-auto text-center wow fadeIn" data-wow-delay="0.1s" style="max-width: 500px;">
                        <div class="btn btn-sm border rounded-pill text-primary px-3 mb-3">Constructor Divi</div>
                        <h1 class="mb-4">Cree páginas de forma visual.</h1>
                    </div>

                    <DiviLicencia />

                </div>
            </div>
        </div>    
    </div>


        <!-- plantillas divi -->
        <div class="container-fluid pb-5 mt-5" id="plantillas">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-12 wow fadeIn" data-wow-delay="0.5s">
                    <div class="mx-auto text-center wow fadeIn" data-wow-delay="0.1s" style="max-width: 500px;">
                        <div class="btn btn-sm border rounded-pill text-primary px-3 mb-3">Plantillas Divi disponibles</div>
                        <h1 class="text-center mb-4">Todos Nuestros planes Incluyen licencia Divi por un año.</h1>
                        
                    </div>
                    <p class="text-center mb-5">Ofrecemos una amplia selección de plantillas web diseñadas específicamente para
                cubrir diversos tipos de comercio, desde diseños corporativos hasta comercio electrónico, todos ellos
                fáciles de adaptar a tu sitio web. Todas las plantillas están en formato .JSON, lo que permite
                importarlas fácilmente en tu página web Divi. Una vez importadas, solo necesitas completar tu
                información y ajustar la configuración del diseño para adaptarlo a tus necesidades exactas. ¡Así de
                sencillo!</p>
 

            

            <iframe src="https://www.elegantthemes.com/layouts/?et_animation=true&#038;et_iframe=1" frameborder="0"
                width="100%" height="5000"></iframe>

        </div>
    </div>
        </div>
    </div>
</div>
    <!-- modales -->

    <PagosPlanesDiseno :plan="plan" :titulo_modal="titulo_modal"/>


</template>

<script>
import PagosPlanesDiseno from '@/components/PagosPlanesDiseno.vue'
import InicioDiseno from '@/components/InicioDiseno.vue'
import DiviLicencia from '@/components/DiviLicencia.vue';
export default {
    name: "PlanesHome",
    components: {
        PagosPlanesDiseno, InicioDiseno, DiviLicencia
    },
    data() {
        return {
            plan: '',
            titulo_modal: '',
        }
    }
}
</script>

<style scoped>

</style>