<template>
    <div class="container">
        <div class="row">
            <div class="col-12 text-center">
                <p>Divi es uno de los temas de WordPress más avanzados del mundo. Divi es más que un simple tema de WordPress: es una plataforma de creación de sitios web completamente nueva que reemplaza el editor de publicaciones estándar de WordPress con un editor visual muy superior.</p>
            </div>
            
        </div>

        <div id="app" class="row mt-5">
    <div class="col-sm-12 col-lg-3 d-none d-md-block">
        <div class="list-group">
            <button type="button" class="list-group-item list-group-item-action p-3 d-flex align-items-center" @click="changeVideo('assets/img/divi/build.mp4')">
                <i class="bi bi-play-circle fs-1"></i><span class="ms-2">Construir visualmente</span>
            </button>
            <button type="button" class="list-group-item list-group-item-action p-3 d-flex align-items-center" @click="changeVideo('assets/img/divi/filters.mp4')">
                <i class="bi bi-play-circle fs-1"></i><span class="ms-2">Efectos</span>
            </button>
            <button type="button" class="list-group-item list-group-item-action p-3 d-flex align-items-center" @click="changeVideo('assets/img/divi/shape-dividers.mp4')">
                <i class="bi bi-play-circle fs-1"></i><span class="ms-2">Divisores de forma</span>
            </button>
            <button type="button" class="list-group-item list-group-item-action p-3 d-flex align-items-center" @click="changeVideo('assets/img/divi/bulk-edit.mp4')">
                <i class="bi bi-play-circle fs-1"></i><span class="ms-2">Edición masiva</span>
            </button>
        </div>
    </div>

    <div class="col-sm-12 col-lg-6 mt-2 d-flex align-items-center">
        <div>
            <video class="img-fluid video rounded" id="video" autoplay loop muted playsinline :src="currentVideo"></video>
            <a role="button" href="https://admin.micode.cl/store/licencias/licencia-divi-wordpress" target="_top" class="btn btn-primary w-100">Comprar Licencia Divi</a>
        </div>

    </div>
    
    <div class="col-sm-12 col-lg-3 mt-2 d-none d-md-block">
        <div class="list-group">
            <button type="button" class="list-group-item list-group-item-action p-3 d-flex align-items-center" @click="changeVideo('assets/img/divi/layout-library.mp4')">
                <i class="bi bi-play-circle fs-1"></i><span class="ms-2">Biblioteca de diseño</span>
            </button>
            <button type="button" class="list-group-item list-group-item-action p-3 d-flex align-items-center" @click="changeVideo('assets/img/divi/transforms.mp4')">
                <i class="bi bi-play-circle fs-1"></i><span class="ms-2">Transformaciones</span>
            </button>
            <button type="button" class="list-group-item list-group-item-action p-3 d-flex align-items-center" @click="changeVideo('assets/img/divi/hover.mp4')">
                <i class="bi bi-play-circle fs-1"></i><span class="ms-2">Estados de hover</span>
            </button>
            <button type="button" class="list-group-item list-group-item-action p-3 d-flex align-items-center" @click="changeVideo('assets/img/divi/find-and-replace.mp4')">
                <i class="bi bi-play-circle fs-1"></i><span class="ms-2">Buscar y reemplazar</span>
            </button>
        </div>
    </div>
</div>

    </div>
</template>

<script>
export default {
    name: "DiviLicencia",

    data() {
        return {
            currentVideo: 'assets/img/divi/build.mp4', // Video por defecto
        };
    },
    methods: {
        changeVideo(videoSrc) {
            this.currentVideo = videoSrc;
        },
    },
};
</script>

<style scoped>
.list-group-item {
    display: flex; /* Usar Flexbox */
    align-items: center; /* Alinear verticalmente */
}

.ms-2 {
    margin-left: 0.5rem; /* Espacio entre el ícono y el texto */
}
</style>

