<template>
    <!-- Hero Start -->
    <div class="container-fluid pt-5 bg-primary hero-header" id="inicio">
        <div class="container pt-5">
            <div class="row g-5">
                <div class="col-lg-6 align-self-center text-center text-lg-start" id="slider">
                    <h1 class="display-4 text-white mb-4 animated slideInRight">El más conveniente del mercado.
                    </h1>
                    <p class="text-white mb-4 animated slideInRight">El hosting ideal es clave para tu éxito online. En Micode, ofrecemos planes diseñados por expertos para brindarte rendimiento, seguridad y soporte personalizados. ¡Enfócate en crecer, nosotros nos encargamos del resto!
                    </p>
                    <a type="button" class="btn btn-outline-light py-sm-3 px-sm-5 rounded-pill animated slideInRight"
                        data-bs-toggle="modal" data-bs-target="#contact">Contáctenos</a>
                </div>
                <div class="col-lg-6 align-self-end text-center text-lg-end mb-5 d-none d-md-block">
                    <embed class=" mb-5"
                        src="https://lottie.host/embed/ae643172-dec8-4715-bff6-3f037e3d2b11/OcjaAjgV0C.json"
                        width="500px" height="500px" />
                </div>
            </div>
        </div>
    </div>
    <!-- Hero End -->

    <!-- modales -->

    <!--Contactenos-->
    <div class="modal fade" id="contact" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header bg-primary">
                    <h1 class="modal-title fs-5 text-light" id="exampleModalLabel">Contáctenos</h1>
                    <button type="button" class="btn text-light fs-3" data-bs-dismiss="modal" aria-label="Close"><i
                            class="fas fa-times"></i></button>
                </div>
                <div class="modal-body">
                    <ContactHome />
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import ContactHome from './ContactHome.vue'
export default {
    name: "InicioHome",
    components: { ContactHome },
}
</script>

<style scoped>
@media (min-width: 768px) {
        #inicio {
            height: 100vh; /* Altura al 100% del viewport */
        }
}

@media (max-width: 767.98px) {
        #slider {
            padding-bottom: 3rem;
        }
    }
</style>
