<template>
    <!-- Contact Start -->
    <div class="modal fade" id="modal_hosting" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header bg-primary">
                    <h1 class="modal-title fs-5 text-light" id="exampleModalLabel">{{ titulo_modal }}</h1>
                    <button type="button" class="btn text-light fs-3" data-bs-dismiss="modal" aria-label="Close">
                        <i class="fas fa-times"></i>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="container-fluid">
                        <div class="container">
                            <div class="list-group list-group-flushwow2 fadeIn" data-wow-delay="0.1s">
                                <label>
                                    <i class="fas fa-bullseye text-danger"></i>
                                    <strong> Importante: </strong>
                                    Antes de proceder a realizar el pago, asegúrese de haber comprado un Dominio con un proveedor Autorizado.
                                </label>
                            </div>
                            <div class="row justify-content-center mt-3">
                                <div class="col-lg-12">
                                    <form @submit="handleSubmit" method="POST" id="form">
                                        <div class="wow2 fadeIn" data-wow-delay="0.3s">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="input-group">
                                                        <span class="input-group-text" id="basic-addon1">www.</span>
                                                        <input type="text" aria-describedby="basic-addon1" class="form-control" placeholder="midominio" aria-label="Nombre de dominio" id="dominio" name="dominio" required style="width: 60%;" v-model="datos.dominio">
                                                        <input type="text" class="form-control" placeholder="com" aria-label="Extensión del dominio" id="apellido_dominio" name="apellido_dominio" required size="3" maxlength="3" v-model="datos.apellido_dominio">
                                                    </div>
                                                    <div class="mt-3 form-check">
                                                        <input type="checkbox" class="form-check-input" id="exampleCheck1" v-model="checkboxChecked">
                                                        <label class="form-check-label" for="exampleCheck1">Confirma que tu dominio es correcto</label>
                                                    </div>
                                                </div>
                                                
                                                <div class="col-12 mt-3">
                                                    <button class="btn btn-primary w-100" type="submit" :disabled="!checkboxChecked || spinner">
                                                        <span v-if="spinner" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                                                        <span v-if="spinner" class="visually-hidden" role="status">Loading...</span>
                                                        <span v-if="!spinner">Solicitar</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "PagosPlanesHosting",
    props: ['plan', 'titulo_modal'],
    data() {
        return {
            spinner: false,
            checkboxChecked: false,
            datos: {
                dominio: '',
                apellido_dominio: ''
            }
        }
    },
    methods: {
        handleSubmit(event) {
            this.spinner = true;
            
            // Concatenar el dominio y la extensión para crear la URL completa
            const fullDomain = `${this.datos.dominio}.${this.datos.apellido_dominio}`;
            const form = document.getElementById("form");
            
            // Modificar la acción del formulario antes de enviarlo
            if (this.plan === "Basico") {
                
                form.action = `https://admin.micode.cl/cart.php?a=add&pid=1&sld=${this.datos.dominio}&tld=.${this.datos.apellido_dominio}`;
                return true; 

            } else if (this.plan === "Avanzado") {

                form.action = `https://admin.micode.cl/cart.php?a=add&pid=2&sld=${this.datos.dominio}&tld=.${this.datos.apellido_dominio}`;
                return true;

            } else if (this.plan === "Premium") {

                form.action = `https://admin.micode.cl/cart.php?a=add&pid=3&sld=${this.datos.dominio}&tld=.${this.datos.apellido_dominio}`;
                return true;
            }
            
            
        }
    },
    mounted() {
        // Limpiar el formulario cuando se carga el componente
        document.getElementById("form").reset();
    }
}
</script>
