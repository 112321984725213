<template>
<!-- Hero Start -->
<div class="container-fluid pt-5 bg-primary hero-header" id="inicio">
    <div class="container pt-5">
        <div class="row g-5">
            <div class="col-lg-6 align-self-center text-center text-lg-start" id="slider">
                <h1 class="display-4 text-white mb-5 animated slideInRight">¡Hacemos tus ideas realidad!</h1>
                <p class="text-white mb-5 animated slideInRight">Nos encantaría conocer tus ideas. Envíanos tus propuestas al equipo de desarrollo, y trabajaremos juntos para hacerlas realidad. ¡Estamos aquí para ayudarte a crear algo increíble y personalizado para ti!.</p>
                <a type="button" class="btn btn-outline-light py-sm-3 px-sm-5 rounded-pill animated slideInRight" data-bs-toggle="modal" data-bs-target="#desarrollo">Contactar Equipo Desarrollo</a>
            </div>
            <div class="col-lg-6 align-self-end text-center text-lg-end my-5 d-none d-md-block">
                <embed class=" mb-5" src="https://lottie.host/embed/39b2f9c3-be71-4bd9-85e4-5c4e713d67ff/i59eqqW77g.json" width="450px" height="450px" />
            </div>
        </div>
    </div>
</div>
<!-- Hero End -->

<!-- Case Start -->
<div class="container-fluid bg-light py-5">
        <div class="container py-5">
            <div class="mx-auto text-center wow fadeIn" data-wow-delay="0.1s" style="max-width: 600px;">
                <div class="btn btn-sm border rounded-pill text-primary px-3 mb-3">Desarrollo a medida</div>
                <h1 class="mb-4">Soluciones únicas, personalizadas y flexibles.</h1>
            </div>
            <div class="row g-4">
                <div class="col-lg-4 wow fadeIn" data-wow-delay="0.3s">
                    <div class="case-item position-relative overflow-hidden rounded mb-2">
                        <img class="img-fluid" src="assets/img/proyecto1.png" alt="">
                        <a class="case-overlay text-decoration-none">
                            <small>Soluciones personalizadas</small>
                            <h5 class="lh-base text-white mb-3">Ofrecemos desarrollos a medida completamente adaptados a las necesidades específicas de cada cliente.</h5>
                            <!-- <span class="btn btn-square btn-primary"><i class="fa fa-arrow-right"></i></span> -->
                        </a>
                    </div>
                </div>
                <div class="col-lg-4 wow fadeIn" data-wow-delay="0.5s">
                    <div class="case-item position-relative overflow-hidden rounded mb-2">
                        <img class="img-fluid" src="assets/img/proyecto2.png" alt="">
                        <a class="case-overlay text-decoration-none">
                            <small>Atención personalizada</small>
                            <h5 class="lh-base text-white mb-3">Ofrecemos un servicio cercano y atento, trabajando de manera directa con cada cliente.</h5>
                            <!-- <span class="btn btn-square btn-primary"><i class="fa fa-arrow-right"></i></span> -->
                        </a>
                    </div>
                </div>
                <div class="col-lg-4 wow fadeIn" data-wow-delay="0.7s">
                    <div class="case-item position-relative overflow-hidden rounded mb-2">
                        <img class="img-fluid" src="assets/img/proyecto3.png" alt="">
                        <a class="case-overlay text-decoration-none">
                            <small>Respaldo en hosting</small>
                            <h5 class="lh-base text-white mb-3">Al ofrecer también servicios de hosting, aseguramos que los sitios y desarrollos tengan un alto rendimiento, estabilidad y soporte.</h5>
                            <!-- <span class="btn btn-square btn-primary"><i class="fa fa-arrow-right"></i></span> -->
                        </a>
                    </div>
                </div>
            </div>

            <div class="row g-4 mt-2">
                <div class="col-lg-4 wow fadeIn" data-wow-delay="0.3s">
                    <div class="case-item position-relative overflow-hidden rounded mb-2">
                        <img class="img-fluid" src="assets/img/proyecto4.png" alt="">
                        <a class="case-overlay text-decoration-none">
                            <small>Flexibilidad tecnológica</small>
                            <h5 class="lh-base text-white mb-3">Tenemos experiencia en una amplia variedad de tecnologías, tanto para el frontend como para el backend</h5>
                            <!-- <span class="btn btn-square btn-primary"><i class="fa fa-arrow-right"></i></span> -->
                        </a>
                    </div>
                </div>
                <div class="col-lg-4 wow fadeIn" data-wow-delay="0.5s">
                    <div class="case-item position-relative overflow-hidden rounded mb-2">
                        <img class="img-fluid" src="assets/img/proyecto5.png" alt="">
                        <a class="case-overlay text-decoration-none">
                            <small>Experiencia en software a medida</small>
                            <h5 class="lh-base text-white mb-3">Tenemos la capacidad de desarrollar aplicaciones y sistemas que se integren con herramientas y plataformas existentes, lo que genera soluciones optimizadas para cada cliente.</h5>
                            <!-- <span class="btn btn-square btn-primary"><i class="fa fa-arrow-right"></i></span> -->
                        </a>
                    </div>
                </div>
                <div class="col-lg-4 wow fadeIn" data-wow-delay="0.7s">
                    <div class="case-item position-relative overflow-hidden rounded mb-2">
                        <img class="img-fluid" src="assets/img/proyecto6.png" alt="">
                        <a class="case-overlay text-decoration-none">
                            <small>Escalabilidad y mantenimiento</small>
                            <h5 class="lh-base text-white mb-3">Los desarrollos no solo están enfocados en el presente, sino también en la posibilidad de escalar y crecer conforme el negocio del cliente se expanda.</h5>
                            <!-- <span class="btn btn-square btn-primary"><i class="fa fa-arrow-right"></i></span> -->
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Case End -->

<!-- modales -->

<!--Contactenos-->
<div class="modal fade" id="contact" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header bg-primary">
                <h1 class="modal-title fs-5 text-light" id="exampleModalLabel">Contactenos</h1>
                <button type="button" class="btn text-light fs-3" data-bs-dismiss="modal" aria-label="Close"><i class="fas fa-times"></i></button>
            </div>
            <div class="modal-body">
                <ContactHome/>
            </div>
        </div>
    </div>
</div>


    <!-- modales -->

    <ContactDesarrollo plan="Desarrollo a la Medida" titulo_modal="Contactar al equipo de Desarrollo" />

</template>

<script>
import ContactDesarrollo from '../components/ContactDesarrollo.vue';
export default {
name: "DesarrolloView",
components: {ContactDesarrollo},
    mounted() {
        //la pagina se vaya al inicio cuando el componente se monta
        window.scrollTo(0, 0);
    }
}
</script>

<style scoped>
    @media (min-width: 768px) {
            #inicio {
                height: 100vh; /* Altura al 100% del viewport */
            }
    }

    @media (max-width: 767.98px) {
            #slider {
                padding-bottom: 3rem;
            }
        }
</style>