<template>
    <!-- FAQs Start -->
    <div class="container-fluid py-5" id="faqs">
        <div class="container py-5">
            <div class="mx-auto text-center wow fadeIn" data-wow-delay="0.1s" style="max-width: 500px;">
                <div class="btn btn-sm border rounded-pill text-primary px-3 mb-3">FAQs</div>
                <h1 class="mb-4">Preguntas Frecuentes</h1>
            </div>
            <div class="row accordionFAQ1">
                <div class="col-lg-6">
                    <div class="accordion">
                        <div class="accordion-item wow fadeIn" data-wow-delay="0.1s">
                            <h2 class="accordion-header" id="headingOne">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                    ¿Cuánto tiempo tarda en desarrollarse un sitio web o aplicación?
                                </button>
                            </h2>
                            <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne"
                                data-bs-parent=".accordionFAQ1">
                                <div class="accordion-body">
                                    El tiempo depende de la complejidad del proyecto, pero proporcionamos un cronograma
                                    detallado al inicio.
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item wow fadeIn" data-wow-delay="0.2s">
                            <h2 class="accordion-header" id="headingTwo">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                     data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    ¿Ofrecen soporte después de la entrega del proyecto?
                                </button>
                            </h2>
                            <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                                data-bs-parent=".accordionFAQ1">
                                <div class="accordion-body">
                                    Sí, ofrecemos soporte técnico y mantenimiento para asegurar que todo funcione
                                    correctamente.
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item wow fadeIn" data-wow-delay="0.3s">
                            <h2 class="accordion-header" id="headingThree">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    ¿Puedo hacer modificaciones al proyecto durante el desarrollo?
                                </button>
                            </h2>
                            <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                                data-bs-parent=".accordionFAQ1">
                                <div class="accordion-body">
                                    Claro, mantenemos una comunicación constante para ajustar el proyecto según tus
                                    necesidades.
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item wow fadeIn" data-wow-delay="0.4s">
                            <h2 class="accordion-header" id="headingFour">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                    ¿El diseño será personalizado para mi negocio?
                                </button>
                            </h2>
                            <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
                                data-bs-parent=".accordionFAQ1">
                                <div class="accordion-body">
                                    Sí, cada sitio web o aplicación es diseñado a medida, teniendo en cuenta tus
                                    objetivos y estilo.
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item wow fadeIn" data-wow-delay="0.5s">
                            <h2 class="accordion-header" id="headingFive">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                    ¿Que medios de pago se aceptan?
                                </button>
                            </h2>
                            <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive"
                                data-bs-parent=".accordionFAQ1">
                                <div class="accordion-body">
                                    Los medios de pago aceptados son tarjetas de crédito y debito.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="accordion">
                        <div class="accordion-item wow fadeIn" data-wow-delay="0.6s">
                            <h2 class="accordion-header" id="headingSix">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                    ¿Qué tipo de documento entregan para la facturación?
                                </button>
                            </h2>
                            <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix"
                                data-bs-parent=".accordionFAQ1">
                                <div class="accordion-body">
                                    En lugar de facturas, emitimos boletas de servicios para todos nuestros servicios.
                                    Si necesitas una copia o más información, no dudes en contactarnos.
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item wow fadeIn" data-wow-delay="0.7s">
                            <h2 class="accordion-header" id="headingSeven">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                    ¿Qúe tipo de aplicaciones móviles diseñan?
                                </button>
                            </h2>
                            <div id="collapseSeven" class="accordion-collapse collapse" aria-labelledby="headingSeven"
                                data-bs-parent=".accordionFAQ1">
                                <div class="accordion-body">
                                    Desarrollamos aplicaciones móviles híbridas y Web apps personalizadas para tu
                                    negocio.
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item wow fadeIn" data-wow-delay="0.8s">
                            <h2 class="accordion-header" id="headingEight">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                    ¿Puedo integrar mi sitio o aplicación con otros sistemas que utilizo?
                                </button>
                            </h2>
                            <div id="collapseEight" class="accordion-collapse collapse" aria-labelledby="headingEight"
                                data-bs-parent=".accordionFAQ1">
                                <div class="accordion-body">
                                    Sí, podemos integrar tu software con herramientas de terceros o sistemas ya
                                    existentes.
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item wow fadeIn" data-wow-delay="0.9s">
                            <h2 class="accordion-header" id="headingNine">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                    ¿Cómo se garantiza la seguridad de los datos de mi aplicación o sitio web?
                                </button>
                            </h2>
                            <div id="collapseNine" class="accordion-collapse collapse" aria-labelledby="headingNine"
                                data-bs-parent=".accordionFAQ1">
                                <div class="accordion-body">
                                    Implementamos las mejores prácticas de seguridad, como encriptación y control de
                                    acceso, para proteger tus datos y los de tus usuarios.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- FAQs Start -->
</template>

<script>
export default {
    name: "FaqsHome"
}
</script>