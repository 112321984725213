<template>
    <!-- Navbar Start -->
    <div class="container-fluid sticky-top">
        <div class="container">
            <nav class="navbar navbar-expand-lg navbar-dark p-0">
                <router-link to="/" class="navbar-brand p-0">
                  <!---->  <img class="img-fluid" src="assets/img/logo_animado.png" alt="">
                </router-link>

                <!-- Botón para colapsar el menú en pantallas pequeñas -->
                <button type="button" class="navbar-toggler ms-auto me-0" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                    <span class="navbar-toggler-icon"></span>
                </button>

                <div class="collapse navbar-collapse" id="navbarCollapse">
                    <div class="navbar-nav ms-auto">
                        <a href="/#inicio" class="nav-item nav-link" :class="urlActive == 'inicio' ? 'active' : ''"
                            @click="urlActive = 'inicio'; toggleMenu()">Inicio</a>
                        <a href="/#plan" class="nav-item nav-link highlight"
                            :class="urlActive == 'plan' ? 'active' : ''" @click="urlActive = 'plan'; toggleMenu()">
                            Planes
                            <span class="underline"></span>
                        </a>
                        <a href="/#about" class="nav-item nav-link" :class="urlActive == 'about' ? 'active' : ''"
                            @click="urlActive = 'about'; toggleMenu()" >Nosotros</a>
                        <a href="/#service" class="nav-item nav-link" :class="urlActive == 'service' ? 'active' : ''"
                            @click="urlActive = 'service'; toggleMenu()">Servicios</a>
                        <a href="/#faqs" class="nav-item nav-link" :class="urlActive == 'faqs' ? 'active' : ''"
                            @click="urlActive = 'faqs'; toggleMenu()">Faqs</a>

                    </div>
                    <router-link class="btn btn-sm btn-light d-none d-lg-block clientes" to="/login">Área Clientes</router-link>
                </div>
            </nav>
        </div>
    </div>

    <!-- Navbar End -->

    <!-- contenido de las paginas -->
    <router-view />

    <!-- Footer Start -->
    <div class="container-fluid bg-dark text-white-50 footer">
        <div class="container">
            <div class="row col text-center py-4">
                        <div>
                            <a role="button" class="btn btn-light rounded-circle mx-1 wow fadeIn btn-redes" data-wow-delay="0.1s" href="#"><i class="bi bi-facebook"></i></a>
                            <a role="button" class="btn btn-light rounded-circle mx-1 wow fadeIn btn-redes" data-wow-delay="0.1s" href="#"><i class="bi bi-instagram"></i></a>
                            <a role="button" class="btn btn-light rounded-circle mx-1 wow fadeIn btn-redes" data-wow-delay="0.1s" href="#"><i class="bi bi-youtube"></i></a>
                            <a role="button" class="btn btn-light rounded-circle mx-1 wow fadeIn btn-redes" data-wow-delay="0.1s" href="#"><i class="bi bi-linkedin"></i></a>
                        </div>
            </div>
        </div>
        <div class="container wow fadeIn" data-wow-delay="0.1s">
            <div class="copyright">
                <div class="row">
                    <div class="col text-center mb-3 mb-md-0">
                        &copy; <a class="border-bottom" href="#">Micode</a>, Todos los derechos reservados.
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Footer End -->

</template>

<script>
    import { RouterLink } from 'vue-router';

    export default {
        name: "App",
        components: {
            RouterLink
        },
        data() {
            return {
                urlActive: 'inicio'
            };
        },
        methods: {
            toggleMenu() {
            // Obtener el colapso de la barra de navegación
            const navbarCollapse = document.getElementById('navbarCollapse');
            // Verificar si el menú está abierto
            const isOpen = navbarCollapse.classList.contains('show');

            if (isOpen) {
                // Usar Bootstrap Collapse para cerrar el menú si está abierto
                const bsCollapse = new bootstrap.Collapse(navbarCollapse);
                bsCollapse.hide();
            }
        }
        }

    };
</script>

<style>
    .highlight {
        font-weight: bold;
        color: #ff6600;
        /* Un color diferente para destacarlo */
    }

    .underline {
        display: block;
        /* Para que la línea ocupe toda la anchura */
        height: 2px;
        /* Grosor de la línea */
        background-color: #ff6600;
        /* Color de la línea */
        transform-origin: center;
        /* Punto de origen para la animación */
        animation: pulsar 1s infinite;
        /* Añade la animación */
    }

    @keyframes pulsar {
        0% {
            transform: scaleX(1);
        }

        50% {
            transform: scaleX(1.2);
            /* Aumenta el tamaño horizontalmente */
        }

        100% {
            transform: scaleX(1);
        }
    }

    .btn-redes :hover {
        color: #ff6600;
        /* Un color diferente para destacarlo */
    }

    /* CREAR HOVER PARA BTN CLIENTES */

    .clientes {
    transition: all 0.3s ease;
    /* Suaviza las transiciones */
}

.clientes:hover {
    color: #ffffff;
    background-color: #ff6600; /* Cambia el fondo en hover */
    border-color: #ff6600; /* Cambia el borde en hover */
}


</style>
