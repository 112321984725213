<template>
    <div class="container-fluid pt-5 bg-primary hero-header" id="inicio">
        <br>
    </div>

    <InicioHosting />

    <!-- Case Start -->
    <div class="container-fluid bg-light pb-5" id="plan">
        <div class="container pt-5">
            <div class="mx-auto text-center wow fadeIn" data-wow-delay="0.1s" style="max-width: 500px;">
                <div class="btn btn-sm border rounded-pill text-primary px-3 mb-3">Nuestros Planes de Hosting</div>
                <h1 class="mb-5">Elige el plan perfecto para tu negocio</h1>
            </div>

            <!-- Inicio Switch de Bootstrap -->

            <div class="d-flex align-items-center switch-container mt-3">
                <!-- Label Mensual -->
                <label class="me-4 switch-label">Mensual </label>

                <!-- Switch de Bootstrap -->
                <div class="form-check form-switch custom-switch">
                    <input class="form-check-input" type="checkbox" id="planSwitch"  v-model="isAnnual">
                    <label class="form-check-label" for="planSwitch"></label>
                </div>

                <!-- Label Anual -->
                <label class="ms-3 switch-label">Anual</label>
            </div>

            <!-- Fin Switch de Bootstrap -->

            <div class="row g-4 my-4 text-center">
                <div class="col-sm-12 col-md-4 col-lg-4">
                    <div class="card mb-4 rounded-3 shadow-sm wow fadeIn" data-wow-delay="0.3s">
                        <div class="card-header py-3">
                            <h4 class="my-0 fw-normal">Básico</h4>
                        </div>
                        <div class="card-body">
                            <h2 class="card-title pricing-card-title">
                                US$ {{ isAnnual ? '3.00' : '6.00' }} 
                                <span class="small-text">/mes</span>
                            </h2>
                            <p v-if="isAnnual" class="text-center">(Valor por Año <strong>US$ 36.00</strong>)</p>
                            <p v-if="!isAnnual" class="text-center">(Valor por Año <strong>US$ 72.00</strong>)</p>
                            <ul class="list-unstyled mt-3 mb-4 text-start">
                                <li><i class="fas fa-check-circle text-success"></i> 1 Dominio de alojamiento.</li>
                                <li><i class="fas fa-check-circle text-success"></i> <strong>300 GB SSD</strong> almacenamiento.</li>
                                <li><i class="fas fa-check-circle text-success"></i> Base de datos <strong>(Ilimitadas)</strong>.</li>
                                <li><i class="fas fa-check-circle text-success"></i> Correos electrónicos <strong>(Ilimitados)</strong>.</li>
                                <li><i class="fas fa-check-circle text-success"></i> Compatible Wordpress.</li>
                                <li><i class="fas fa-check-circle text-success"></i> Certificado SSL.</li>
                                <li><i class="fas fa-check-circle text-success"></i> Cuentas FTP <strong>(Ilimitadas)</strong>.</li>
                                <li><i class="fas fa-check-circle text-success"></i> LiteSpeed <strong>(WordPress)</strong>.</li>
                                <li><i class="fas fa-check-circle text-success"></i> Jetbackup.</li>
                                <li><i class="fas fa-check-circle text-success"></i> Instalador App.</li>
                                <li><i class="fas fa-check-circle text-success"></i> Sub dominios <strong>(Ilimitados)</strong>.</li>
                                <li>&nbsp;</li>
                                <li>&nbsp;</li>
                                <li>&nbsp;</li>
                                <li><i class="fas fa-bullseye text-danger"></i><strong> Sugerencia: </strong>Ahorra hasta un 50% contratando planes anuales.</li>

                            </ul>
                            <button type="button" class="w-100 btn btn-lg btn-primary" data-bs-toggle="modal"
                                data-bs-target="#modal_hosting"
                                @click="plan = 'Basico'; titulo_modal = 'Contratar Hosting Basico'; limpiar()">Contratar</button>

                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-4">
                    <div class="card mb-4 rounded-3 shadow border border-success wow fadeIn" data-wow-delay="0.3s">
                        <div class="card-header py-3 bg-primary">
                            <h4 class="my-0 fw-normal text-white">Avanzado</h4>
                        </div>
                        <div class="card-body">
                            <h2 class="card-title pricing-card-title">
                                US$ {{ isAnnual ? '5.00' : '10.00' }}
                                <span class="small-text">/mes</span>
                            </h2>
                            <p v-if="isAnnual" class="text-center">(Valor por Año <strong>US$ 60.00</strong>)</p>
                            <p v-if="!isAnnual" class="text-center">(Valor por Año <strong>US$ 120.00</strong>)</p>
                            <ul class="list-unstyled mt-3 mb-4 text-start">
                                <li><i class="fas fa-check-circle text-success"></i> 2 Dominio de alojamiento.</li>
                                <li><i class="fas fa-check-circle text-success"></i> <strong>500 GB SSD</strong> almacenamiento.</li>
                                <li><i class="fas fa-check-circle text-success"></i> Base de datos <strong>(Ilimitadas)</strong>.</li>
                                <li><i class="fas fa-check-circle text-success"></i> Correos electrónicos <strong>(Ilimitados)</strong>.</li>
                                <li><i class="fas fa-check-circle text-success"></i> Compatible Wordpress.</li>
                                <li><i class="fas fa-check-circle text-success"></i> Certificado SSL.</li>
                                <li><i class="fas fa-check-circle text-success"></i> Cuentas FTP <strong>(Ilimitadas)</strong>.</li>
                                <li><i class="fas fa-check-circle text-success"></i> LiteSpeed <strong>(WordPress)</strong>.</li>
                                <li><i class="fas fa-check-circle text-success"></i> Jetbackup.</li>
                                <li><i class="fas fa-check-circle text-success"></i> Instalador App.</li>
                                <li><i class="fas fa-check-circle text-success"></i> Sub dominios <strong>(Ilimitados)</strong>.</li>
                                <li><i class="fas fa-check-circle text-success"></i> Constructor Web.</li>
                                <li>&nbsp;</li>
                                <li>&nbsp;</li>
                                <li><i class="fas fa-bullseye text-danger"></i><strong> Sugerencia: </strong>Ahorra hasta un 50% contratando planes anuales.</li>

                            </ul>
                            <button type="button" class="w-100 btn btn-lg btn-primary" data-bs-toggle="modal"
                                data-bs-target="#modal_hosting"
                                @click="plan = 'Avanzado'; titulo_modal = 'Contratar Hosting Avanzado'; limpiar()">Contratar</button>

                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-4">
                    <div class="card mb-4 rounded-3 shadow-sm wow fadeIn" data-wow-delay="0.3s">
                        <div class="card-header py-3 text-white">
                            <h4 class="my-0 fw-normal">Premium</h4>

                        </div>
                        <div class="card-body">
                            <h2 class="card-title pricing-card-title">
                                US$ {{ isAnnual ? '8.33' : '15.00' }}
                                <span class="small-text">/mes</span>
                            </h2>
                            <p v-if="isAnnual" class="text-center">(Valor por Año <strong>US$ 100.00</strong>)</p>
                            <p v-if="!isAnnual" class="text-center">(Valor por Año <strong>US$ 180.00</strong>)</p>
                            <ul class="list-unstyled mt-3 mb-4 text-start">
                                <li><i class="fas fa-check-circle text-success"></i> 5 Dominio de alojamiento.</li>
                                <li><i class="fas fa-check-circle text-success"></i> <strong>700 GB SSD</strong> almacenamiento.</li>
                                <li><i class="fas fa-check-circle text-success"></i> Base de datos <strong>(Ilimitadas)</strong>.</li>
                                <li><i class="fas fa-check-circle text-success"></i> Correos electrónicos <strong>(Ilimitados)</strong>.</li>
                                <li><i class="fas fa-check-circle text-success"></i> Compatible Wordpress.</li>
                                <li><i class="fas fa-check-circle text-success"></i> Certificado SSL.</li>
                                <li><i class="fas fa-check-circle text-success"></i> Cuentas FTP <strong>(Ilimitadas)</strong>.</li>
                                <li><i class="fas fa-check-circle text-success"></i> LiteSpeed <strong>(WordPress)</strong>.</li>
                                <li><i class="fas fa-check-circle text-success"></i> Jetbackup.</li>
                                <li><i class="fas fa-check-circle text-success"></i> Instalador App.</li>
                                <li><i class="fas fa-check-circle text-success"></i> Sub dominios <strong>(Ilimitados)</strong>.</li>
                                <li><i class="fas fa-check-circle text-success"></i> Constructor Web.</li>
                                <li><i class="fas fa-check-circle text-success"></i> Licencia DIVI.</li>
                                <li>&nbsp;</li>
                                <li><i class="fas fa-bullseye text-danger"></i><strong> Sugerencia: </strong>Ahorra hasta un 50% contratando planes anuales.</li>
                            </ul>
                            <button type="button" class="w-100 btn btn-lg btn-primary" data-bs-toggle="modal"
                                data-bs-target="#modal_hosting"
                                @click="plan = 'Premium'; titulo_modal = 'Contratar Hosting Premium'; limpiar()">Contratar</button>

                        </div>
                    </div>
                </div>

                <div class="d-none d-md-block">
                    <h2 class="display-6 text-center mb-4">Características</h2>

                    <div class="table-responsive">
                        <table class="table text-center">
                            <thead>
                                <tr>
                                    <th style="width: 34%;"></th>
                                    <th style="width: 22%;">Básico</th>
                                    <th style="width: 22%;">Avanzado</th>
                                    <th style="width: 22%;">Premium</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row" class="text-start">Dominio de Alojamiento</th>
                                    <td><i class="bi bi-check-circle text-success"></i> 1</td>
                                    <td><i class="bi bi-check-circle text-success"></i> 2</td>
                                    <td><i class="bi bi-check-circle text-success"></i> 5</td>
                                </tr>
                                <tr>
                                    <th scope="row" class="text-start">Base de datos</th>
                                    <td><i class="bi bi-check-circle text-success"></i> 1</td>
                                    <td><i class="bi bi-check-circle text-success"></i> 5</td>
                                    <td><i class="bi bi-check-circle text-success"></i> Ilimitadas</td>
                                </tr>
                                <tr>
                                    <th scope="row" class="text-start">Almacenamiento</th>
                                    <td><i class="bi bi-check-circle text-success"></i> 300 GB SSD</td>
                                    <td><i class="bi bi-check-circle text-success"></i> 500 GB SSD</td>
                                    <td><i class="bi bi-check-circle text-success"></i> 700 GB SSD</td>
                                </tr>
                                <tr>
                                    <th scope="row" class="text-start">Correos electrónicos</th>
                                    <td><i class="bi bi-check-circle text-success"></i> Ilimitados</td>
                                    <td><i class="bi bi-check-circle text-success"></i> Ilimitados</td>
                                    <td><i class="bi bi-check-circle text-success"></i> Ilimitados</td>
                                </tr>
                                <tr>
                                    <th scope="row" class="text-start">Compatibilidad Wordpress</th>
                                    <td><i class="bi bi-check-circle text-success"></i></td>
                                    <td><i class="bi bi-check-circle text-success"></i></td>
                                    <td><i class="bi bi-check-circle text-success"></i></td>
                                </tr>
                                <tr>
                                    <th scope="row" class="text-start">Certificado SSL</th>
                                    <td><i class="bi bi-check-circle text-success"></i></td>
                                    <td><i class="bi bi-check-circle text-success"></i></td>
                                    <td><i class="bi bi-check-circle text-success"></i></td>
                                </tr>
                                <tr>
                                    <th scope="row" class="text-start">Soporte técnico</th>
                                    <td><i class="bi bi-check-circle text-success"></i></td>
                                    <td><i class="bi bi-check-circle text-success"></i></td>
                                    <td><i class="bi bi-check-circle text-success"></i></td>
                                </tr>
                                <tr>
                                    <th scope="row" class="text-start">Subdominios</th>
                                    <td></td>
                                    <td><i class="bi bi-check-circle text-success"></i> Ilimitados</td>
                                    <td><i class="bi bi-check-circle text-success"></i> Ilimitados</td>
                                </tr>
                                <tr>
                                    <th scope="row" class="text-start">Constructor web</th>
                                    <td></td>
                                    <td><i class="bi bi-check-circle text-success"></i></td>
                                    <td><i class="bi bi-check-circle text-success"></i></td>
                                </tr>
                                <tr>
                                    <th scope="row" class="text-start">Licencia DIVI</th>
                                    <td></td>
                                    <td></td>
                                    <td><i class="bi bi-check-circle text-success"></i></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>


            </div>
        </div>
    </div>

    <div class="container-fluid pb-5 mt-5" id="licencias">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-12 wow fadeIn" data-wow-delay="0.5s">
                    <div class="mx-auto text-center wow fadeIn" data-wow-delay="0.1s" style="max-width: 500px;">
                        <div class="btn btn-sm border rounded-pill text-primary px-3 mb-3">Constructor Divi</div>
                        <h1 class="mb-4">Cree páginas de forma visual.</h1>
                    </div>

                    <DiviLicencia />

                </div>
            </div>
        </div>    
    </div>

    <div class="container-fluid pb-5 mt-5" id="herramientas">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12 wow fadeIn" data-wow-delay="0.5s">
                <div class="mx-auto text-center wow fadeIn" data-wow-delay="0.1s" style="max-width: 500px;">
                    <div class="btn btn-sm border rounded-pill text-primary px-3 mb-3">Complementos</div>
                    <h1 class="mb-4">Herramientas Gratuitas por cada Plan.</h1>
                </div>

                <div class="row g-3 my-5">
                    <div class="col-sm-4 d-flex justify-content-center">
                        <div class="card wow fadeIn" data-wow-delay="0.5s" style="width: 20rem; height: 350px;">
                            <img src="assets/img/1.png" class="card-img-top img-card" alt="Cpanel">
                            <div class="card-body">
                                <p class="card-text">Administre sus sitios con facilidad a través de nuestro <strong>Panel de Control con solo apuntar y hacer clic.</strong> Dominio, Correo electrónico, Archivos, SQL y Cortafuegos.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4 d-flex justify-content-center">
                        <div class="card wow fadeIn" data-wow-delay="0.5s" style="width: 20rem; height: 350px;">
                            <img src="assets/img/2.png" class="card-img-top img-card" alt="Cpanel">
                            <div class="card-body">
                                <p class="card-text">Cree sus propios sitios fácilmente a través de nuestro <strong>generador de sitios web con función de arrastrar y soltar</strong>. Diseño totalmente adaptable. Más de 100 temas.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4 d-flex justify-content-center">
                        <div class="card wow fadeIn" data-wow-delay="0.5s" style="width: 20rem; height: 350px;">
                            <img src="assets/img/3.png" class="card-img-top img-card" alt="Cpanel">
                            <div class="card-body">
                                <p class="card-text">Lanza tu blog o tienda electrónica con nuestro <strong>instalador de aplicaciones web de un solo clic</strong>. Más de 100 aplicaciones gratuitas. Instalación con un solo clic.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


    <div class="container-fluid pb-5 mt-5" id="litespeed">
        <div class="container">
            <div class="row g-5 align-items-center">
                <div class="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                    <div class="litespeed-img">
                        <video class="img-fluid video" autoplay loop muted playsinline>
                            <source src="assets/img/litespeed.mp4" type="video/mp4">
                        </video>
                    </div>

                </div>
                <div class="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                    <div class="btn btn-sm border rounded-pill text-primary px-3 mb-3">LiteSpeed</div>
                    <h1 class="mb-4">Nuestros Servidores con LiteSpeed
                    </h1>
                    <p class="mb-4">Nuestros servidores cuentan con la tecnología LiteSpeed + LSCache, la
                        mejor opción en performance y velocidad para WordPress. Un sitio web que descarga
                        más rápido te permitirá ofrecer una experiencia única a tus visitantes y las mejores
                        métricas de "Google Core Web Vitals".</p>

                    <div class="row g-3">
                        <div class="col-sm-6">
                            <h6 class="mb-3"><i class="fa fa-check text-primary me-2"></i>Compatible con Wordpress.</h6>
                            <h6 class="mb-0"><i class="fa fa-check text-primary me-2"></i>Certificados SSL.</h6>
                        </div>
                        <div class="col-sm-6">
                            <h6 class="mb-3"><i class="fa fa-check text-primary me-2"></i>Servidores con LiteSpeed.</h6>
                            <h6 class="mb-0"><i class="fa fa-check text-primary me-2"></i>PHP hasta 8.1.</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid pb-5 mt-5" id="jetbackup">
        <div class="container">
            <div class="row g-5 align-items-center">
                <div class="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                    <div class="btn btn-sm border rounded-pill text-primary px-3 mb-3">JetBackup</div>
                    <h1 class="mb-4">Backup Rápido, seguro y confiable.
                    </h1>
                    <p class="mb-4">Jetbackup es un complemento para cPanel que tiene la funcionalidad de ayudar a restaurar y obtener copias de seguridad de su cuenta de cPanel, lo que le permite descargar o restaurar archivos, bases de datos y correos electrónicos.</p>

                    <div class="row g-3">
                        <div class="col-sm-6">
                            <h6 class="mb-3"><i class="fa fa-check text-primary me-2"></i>Respaldo tu Contenido.</h6>
                            <h6 class="mb-0"><i class="fa fa-check text-primary me-2"></i>Respalda tus Bases de datos.</h6>
                        </div>
                        <div class="col-sm-6">
                            <h6 class="mb-3"><i class="fa fa-check text-primary me-2"></i>Respalda tus Email.</h6>
                            <h6 class="mb-0"><i class="fa fa-check text-primary me-2"></i>Confiable y Seguro.</h6>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                    <div class="jetbackup-img">
                        <video class="img-fluid video" autoplay loop muted playsinline>
                            <source src="assets/img/JetBackup.mp4" type="video/mp4">
                        </video>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <PagosPlanesHosting :plan="plan" :titulo_modal="titulo_modal"/>

</template>

<script>
import PagosPlanesHosting from '@/components/PagosPlanesHosting.vue'
import InicioHosting from '@/components/InicioHosting.vue'
import DiviLicencia from '@/components/DiviLicencia.vue';

export default {
    name: "PlanesHome",
    components: {
        PagosPlanesHosting,
        InicioHosting,
        DiviLicencia
    },
    data() {
        return {
            isAnnual: true,
            plan: '',
            titulo_modal: '',
        }
    },
    methods: {
        limpiar() {
            document.getElementById("form").reset();
        }
    }
}
</script>

<style scoped>
#litespeed{
    background-color: white;
}

.video {
    width: 400px;
    height: 400px;
    object-fit: cover;
}

#jetbackup{
    background-color: #ffffff;
}

.jetbackup-img{
/*alinear contenido del div a la derecha*/
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.pricing-card-title {
    font-size: 2.5rem; /* Tamaño grande para el valor */
    font-weight: bold;
}

.small-text {
    font-size: 1rem; /* Tamaño más pequeño para "/mes" */
    vertical-align: super; /* Alinea "/mes" a la parte superior del número */
    font-weight: normal;
}

.img-card{
    width: 150px;
    height: 150px;
    margin: auto
}

.custom-switch {
    transform: scale(1.5); /* Aumenta el tamaño del switch */
}
.switch-container {
    justify-content: center; /* Centra el contenido horizontalmente */
}
.switch-label {
    font-size: 1.5rem; /* Aumenta el tamaño del texto de los labels */
}

</style>