import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Diseno from '../views/DisenoView.vue'
import Desarrollo from '../views/DesarrolloView.vue'
import Hosting from '../views/HostingView.vue'
import DisenoExito from '../views/ExitoDiseno.vue'
import HostingExito from '../views/ExitoHosting.vue'
import ErrorView from '@/views/ErrorView.vue'
import LoginView from '@/views/LoginView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/diseno',
    name: 'diseno',
    component: Diseno
  },
  {
    path: '/desarrollo',
    name: 'desarrollo',
    component: Desarrollo
  },
  {
    path: '/hosting',
    name: 'hosting',
    component: Hosting
  },
  {
    path: '/diseno_exito',
    name: 'disenoexito',
    component: DisenoExito
  },
  {
    path: '/hosting_exito',
    name: 'hostingexito',
    component: HostingExito
  },
  {
    path: '/error',
    name: 'error',
    component: ErrorView
  },
  ,
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
