<template>
    <!-- Hero Start -->
<div class="container-fluid pt-5 bg-primary hero-header">
    <div class="container pt-5">
        <div class="row g-5">
            <div class="col-lg-6 align-self-center text-center text-lg-start mb-lg-5">
                <h1 class="display-4 text-white mb-4 animated slideInRight">¡Ups! Algo salió mal...</h1>
                <p class="text-white mb-4 animated slideInRight">Parece que hemos encontrado un problema mientras procesábamos tu solicitud. No te preocupes, aquí te damos algunas opciones para resolverlo:</p>
                <a href="" class="btn btn-light py-sm-3 px-sm-5 rounded-pill me-3 animated slideInRight" data-bs-toggle="modal" data-bs-target="#chat">Ayuda</a>
                <a type="button" class="btn btn-outline-light py-sm-3 px-sm-5 rounded-pill animated slideInRight" data-bs-toggle="modal" data-bs-target="#contact">Contactenos</a>
            </div>
            <div class="col-lg-6 align-self-end text-center text-lg-end mb-5">
                <embed class=" mb-5" src="https://lottie.host/embed/ae1f6f4e-f960-4e5c-a069-92bdf666346a/AgY3AFt55E.json" width="500px" height="500px" />
            </div>
        </div>
    </div>
</div>
<!-- Hero End -->

<!-- modales -->

<!--Contactenos-->
<div class="modal fade" id="contact" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header bg-primary">
                <h1 class="modal-title fs-5 text-light" id="exampleModalLabel">Contactenos</h1>
                <button type="button" class="btn text-light fs-3" data-bs-dismiss="modal" aria-label="Close"><i class="fas fa-times"></i></button>
            </div>
            <div class="modal-body">
                <ContactHome/>
            </div>
        </div>
    </div>
</div>

<!--Chat online-->
<div class="modal fade" id="chat" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header  bg-primary">
                <h1 class="modal-title fs-5  text-light" id="exampleModalLabel">Ayuda Agentes</h1>
                <button type="button" class="btn text-light fs-3" data-bs-dismiss="modal" aria-label="Close"><i class="fas fa-times"></i></button>
            </div>
            <div class="modal-body">
                <iframe src="https://tawk.to/chat/66e2e52aea492f34bc126d7a/1i7j4ubrp" style="width: 100%; height: 480px; border: none;" scrolling="no" frameborder="0"></iframe>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import ContactHome from './../components/ContactHome.vue'
export default {
name: "ErrorView",
components: {ContactHome},
}
</script>
