<template>
    <!-- Contact Start -->
    <div class="container-fluid">
        <div class="container">
            <div style="position: relative; width: 100%; height: 0; padding-top: 77.2727%;
 padding-bottom: 0; box-shadow: 0 2px 8px 0 rgba(63,69,81,0.16); margin-top: 1.6em; margin-bottom: 0.9em; overflow: hidden;
 border-radius: 8px; will-change: transform;">
  <iframe loading="lazy" style="position: absolute; width: 100%; height: 100%; top: 0; left: 0; border: none; padding: 0;margin: 0;"
    src="https://www.canva.com/design/DAGUfcVwJOw/Ae334xVoCbo-Ko1G26-_7w/view?embed" allowfullscreen="allowfullscreen" allow="fullscreen">
  </iframe>
</div>
<a href="https:&#x2F;&#x2F;www.canva.com&#x2F;design&#x2F;DAGUfcVwJOw&#x2F;Ae334xVoCbo-Ko1G26-_7w&#x2F;view?utm_content=DAGUfcVwJOw&amp;utm_campaign=designshare&amp;utm_medium=embeds&amp;utm_source=link" target="_blank" rel="noopener">Brochure micode</a></div>

    </div>




    <!-- Contact End -->
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';

export default {
    name: "ContactHome",
    data() {
        return {
            datos: {
                nombre: "",
                email: "",
                asunto: "",
                mensaje: ""
            },
            spinner: false
        }
    },
    methods: {
        enviar() {
            this.spinner = true;
            axios
                .post('https://www.iif.cl/api_slim/correo', this.datos)
                .then(response => console.log(response))
                .catch(error => console.log(error))
                .finally(() => {
                    this.datos = "";
                    this.spinner = false;
                    
                    const Toast = Swal.mixin({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.onmouseenter = Swal.stopTimer;
                        toast.onmouseleave = Swal.resumeTimer;
                    }
                    });
                    Toast.fire({
                    icon: "success",
                    title: "Mensaje enviado"
                    });

                })
        }
    }
}
</script>
