<template>
    <!-- Contact Start -->
    <div class="modal fade" id="general" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header bg-primary">
                    <h1 class="modal-title fs-5 text-light" id="exampleModalLabel">{{ titulo_modal }}</h1>
                    <button type="button" class="btn text-light fs-3" data-bs-dismiss="modal" aria-label="Close">
                        <i class="fas fa-times"></i>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="container-fluid">
                        <div class="container">
                            <div class="list-group list-group-flushwow2 fadeIn" data-wow-delay="0.1s">
                                <label>
                                    <i class="fas fa-bullseye text-danger"></i>
                                    <strong> Nota: </strong>
                                    Una vez confirmado el pago, nuestro equipo se pondrá en contacto con usted a la brevedad, se creara automaticamente un ticket de atención para su gestion.
                                </label>
                            </div>
                            <div class="row justify-content-center mt-3">
                                <div class="col-lg-12">
                                        <div class="wow2 fadeIn" data-wow-delay="0.3s">
                                            <div class="row">
                                                <div class="col-12 mt-3">
                                                    <button class="btn btn-primary w-100" type="button" :disabled="spinner" @click="redireccionar()">
                                                        <span v-if="spinner" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                                                        <span v-if="spinner" class="visually-hidden" role="status">Loading...</span>
                                                        <span v-if="!spinner">Contratar</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "PagosPlanesDiseno",
    props: ['plan', 'titulo_modal'],
    data() {
        return {
            spinner: false
        }
    },
    methods: {
        redireccionar() {
            this.spinner = true;
           //redireccionar a pagina segun plan
            if (this.plan === "Esencial") {
                window.location.href = "https://admin.micode.cl/cart.php?a=add&pid=4&carttpl=standard_cart";
            }else if (this.plan === "Avanzado") {
                window.location.href = "https://admin.micode.cl/cart.php?a=add&pid=5&carttpl=standard_cart";
            }else if (this.plan === "Premium") {
                window.location.href = "https://admin.micode.cl/cart.php?a=add&pid=6&carttpl=standard_cart";
            }
        }
    }
}
</script>