<template>
    <!-- Contact Start -->
    <div class="container-fluid">
        <div class="container">
            <div class="mx-auto text-center wow fadeIn" data-wow-delay="0.1s" style="max-width: 500px;">
                <h1 class="mb-4">Si tiene alguna consulta, contáctenos</h1>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-12">
                    <form @submit.prevent="enviar">
                        <div class="wow fadeIn" data-wow-delay="0.3s">
                            <div class="row g-3">
                                <div class="col-md-6">
                                    <div class="form-floating">
                                        <input type="text" class="form-control" id="name" placeholder="Su Nombre"
                                            v-model="datos.nombre" required>
                                        <label for="name">Nombre</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-floating">
                                        <input type="email" class="form-control" id="email" placeholder="Su Email"
                                            v-model="datos.email" required>
                                        <label for="email">Email</label>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-floating">
                                        <input type="text" class="form-control" id="subject" placeholder="Asunto"
                                            v-model="datos.asunto" required>
                                        <label for="subject">Asunto</label>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-floating">
                                        <textarea class="form-control" placeholder="Escriba un mensaje" id="message"
                                            v-model="datos.mensaje" style="height: 150px" required></textarea>
                                        <label for="message">Mensaje</label>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <button class="btn btn-primary w-100 py-3" type="submit" :disabled="spinner">
                                        <span v-if="spinner" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                                        <span class="visually-hidden" role="status">Loading...</span>
                                        Enviar Mensaje
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>




    <!-- Contact End -->
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';

export default {
    name: "ContactHome",
    data() {
        return {
            datos: {
                nombre: "",
                email: "",
                asunto: "",
                mensaje: ""
            },
            spinner: false
        }
    },
    methods: {
        enviar() {
            this.spinner = true;
            axios
                .post('https://www.iif.cl/api_slim/correo', this.datos)
                .then(response => console.log(response))
                .catch(error => console.log(error))
                .finally(() => {
                    this.datos = "";
                    this.spinner = false;
                    
                    const Toast = Swal.mixin({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.onmouseenter = Swal.stopTimer;
                        toast.onmouseleave = Swal.resumeTimer;
                    }
                    });
                    Toast.fire({
                    icon: "success",
                    title: "Mensaje enviado"
                    });

                })
        }
    }
}
</script>