<template>
    <!-- Contact Start -->
    <div class="modal fade" id="desarrollo" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header bg-primary">
                    <h1 class="modal-title fs-5 text-light" id="exampleModalLabel">{{ titulo_modal }}</h1>
                    <button type="button" class="btn text-light fs-3" data-bs-dismiss="modal" aria-label="Close"><i
                            class="fas fa-times"></i></button>
                </div>
                <div class="modal-body">
                    <div class="container-fluid">
                        <div class="container">
                            <div class="mx-auto text-center wow2 fadeIn" data-wow-delay="0.1s"
                                style="max-width: 500px;">
                            </div>
                            
                            <div>
                                <div class="list-group list-group-flush">
                                    <label><i class="fas fa-bullseye text-danger"></i><strong> Nota: </strong>Una vez enviado el formulario, nuestro equipo se pondrá en contacto con usted a la brevedad para hacer realidad su idea.</label>                         
                                </div>

                            </div>
                            <div class="row justify-content-center mt-3">
                                <div class="col-lg-12">
                                    <form @submit.prevent="contratar">
                                        <div class="wow2 fadeIn" data-wow-delay="0.3s">
                                            <div class="row g-3">
                                                <div class="col-md-6">
                                                    <div class="form-floating">
                                                        <input type="text" class="form-control" id="name"
                                                            placeholder="Su Nombre" v-model="datos.nombre" required>
                                                        <label for="name">Nombre</label>
                                                        
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-floating">
                                                        <input type="email" class="form-control" id="email"
                                                            placeholder="Su Email" v-model="datos.email" required>
                                                        <label for="email">Email</label>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="form-floating">
                                                        <input type="text" class="form-control" id="direccion"
                                                            placeholder="Su Dirección" v-model="datos.direccion"
                                                            required>
                                                        <label for="direccion">Dirección</label>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-floating">
                                                        <input type="number" class="form-control" id="telefono"
                                                            placeholder="Su Teléfono" v-model="datos.telefono" required>
                                                        <label for="telefono">Teléfono</label>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-floating">
                                                        <input type="text" class="form-control" id="rut"
                                                            placeholder="Su Rut" v-model="datos.rut" required>
                                                        <label for="rut">Rut</label>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <button class="btn btn-primary w-100 py-3" type="submit"
                                                        :disabled="spinner">
                                                        <span v-if="spinner" class="spinner-border spinner-border-sm"
                                                            aria-hidden="true"></span>
                                                        <span class="visually-hidden" role="status">Loading...</span>
                                                        Solicitar
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    </div>

</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';
export default {
    name: "ContactDesarrollo",
    props: ["plan", "titulo_modal"],
    data() {
        return {
            datos: {
                nombre: "",
                email: "",
                direccion: "",
                telefono: "",
                rut: "",
                plan: ""
            },
            spinner: false
        }
    },
    methods: {
        contratar() {
            this.spinner = true;
            this.datos.plan = this.plan;
            axios
                .post("https://www.iif.cl/api_slim/contrato", this.datos)
                .then(response => console.log(response))
                .catch(error => console.log(error))
                .finally(() => {
                    this.spinner = false

                    if (this.datos.plan === "Desarrollo a la Medida") {
                        this.datos = "";
                        //cerrar modal con id general
                        $("#desarrollo").modal("hide");

                        //mostrar mensaje enviado con exito
                        
                        const Toast = Swal.mixin({
                        toast: true,
                        position: "top-end",
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.onmouseenter = Swal.stopTimer;
                            toast.onmouseleave = Swal.resumeTimer;
                        }
                        });
                        Toast.fire({
                        icon: "success",
                        title: "Solicitud enviada con Exito"
                        });

                    }
                })
        }
    }
}
</script>