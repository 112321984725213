<template>

    <!-- Service Start -->
    <div class="container-fluid bg-light mt-5 py-5" id="service">
        <div class="container py-5">
            <div class="row g-5 align-items-center">
                <div class="col-lg-5 wow fadeIn" data-wow-delay="0.1s">
                    <div class="btn btn-sm border rounded-pill text-primary px-3 mb-3">Nuestros Servicios</div>
                    <h1 class="mb-4">Soluciones tecnológicas para impulsar tu negocio</h1>
                    <p class="mb-4">En Micode, ofrecemos servicios completos de desarrollo de software
                        para satisfacer las necesidades digitales de tu empresa. Nuestro equipo está especializado en
                        crear productos personalizados y efectivos.</p>

                </div>
                <div class="col-lg-7">
                    <div class="row g-4">
                        <div class="col-md-6">
                            <div class="row g-4">
                                <div class="col-12 wow fadeIn" data-wow-delay="0.1s">
                                    <div
                                        class="service-item d-flex flex-column justify-content-center text-center rounded">
                                        <div class="service-icon btn-square">
                                            <i class="fa fa-globe fa-2x"></i>
                                        </div>
                                        <h5 class="mb-3">Desarrollo Web</h5>
                                        <p>Diseñamos sitios web modernos y funcionales.</p>
                                        <a type="button" class="btn px-3 mt-auto mx-auto" href="#" data-bs-toggle="modal" data-bs-target="#sweb">Más Información</a>
                                    </div>
                                </div>
                                <div class="col-12 wow fadeIn" data-wow-delay="0.5s">
                                    <div
                                        class="service-item d-flex flex-column justify-content-center text-center rounded">
                                        <div class="service-icon btn-square">
                                            <i class="fa fa-wrench fa-2x"></i>

                                        </div>
                                        <h5 class="mb-3">Software a Medida</h5>
                                        <p>Soluciones personalizadas para optimizar tus procesos.</p>
                                        <a type="button" class="btn px-3 mt-auto mx-auto" href="#" data-bs-toggle="modal" data-bs-target="#ssoftware">Más Información</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row g-4">
                                <div class="col-12 wow fadeIn" data-wow-delay="0.3s">
                                    <div
                                        class="service-item d-flex flex-column justify-content-center text-center rounded">
                                        <div class="service-icon btn-square">
                                            <i class="fa fa-mobile fa-2x"></i>
                                        </div>
                                        <h5 class="mb-3">Desarrollo de Apps (Híbridas y Web apps)</h5>
                                        <p>Creamos apps móviles a medida para tu negocio.</p>
                                        <a type="button" class="btn px-3 mt-auto mx-auto" href="#" data-bs-toggle="modal" data-bs-target="#sapp">Más Información</a>
                                    </div>
                                </div>
                                <div class="col-12 wow fadeIn" data-wow-delay="0.7s">
                                    <div
                                        class="service-item d-flex flex-column justify-content-center text-center rounded">
                                        <div class="service-icon btn-square">
                                            <i class="fa fa-users fa-2x"></i>
                                        </div>
                                        <h5 class="mb-3">Consultoría Tecnológica</h5>
                                        <p>Asesoría profesional para proyectos tecnológicos.</p>
                                        <a type="button" class="btn px-3 mt-auto mx-auto"  href="#" data-bs-toggle="modal" data-bs-target="#sconsultoria">Más Información</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Service End -->
    <!-- modales -->
        <!--Desarrollo Web-->
        <div class="modal fade" id="sweb" tabindex="-1" aria-labelledby="webModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header  bg-primary">
                        <h1 class="modal-title fs-5  text-light" id="webModalLabel">Desarrollo Web</h1>
                        <button type="button" class="btn text-light fs-3" data-bs-dismiss="modal" aria-label="Close"><i class="fas fa-times"></i></button>
                    </div>
                    <div class="modal-body">
                        <img class="img-fluid" src="assets/img/web.png" alt="">
                    </div>
                </div>
            </div>
        </div>

        <!--Desarrolo Apps-->
        <div class="modal fade" id="sapp" tabindex="-1" aria-labelledby="appModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header  bg-primary">
                        <h1 class="modal-title fs-5  text-light" id="appModalLabel">Desarrollo Apps</h1>
                        <button type="button" class="btn text-light fs-3" data-bs-dismiss="modal" aria-label="Close"><i class="fas fa-times"></i></button>
                    </div>
                    <div class="modal-body">
                        <img class="img-fluid" src="assets/img/app.png" alt="">
                    </div>
                </div>
            </div>
        </div>

        <!--Software a medida-->
        <div class="modal fade" id="ssoftware" tabindex="-1" aria-labelledby="appModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header  bg-primary">
                        <h1 class="modal-title fs-5  text-light" id="appModalLabel">Software a medida</h1>
                        <button type="button" class="btn text-light fs-3" data-bs-dismiss="modal" aria-label="Close"><i class="fas fa-times"></i></button>
                    </div>
                    <div class="modal-body">
                        <img class="img-fluid" src="assets/img/desarrollo.png" alt="">
                    </div>
                </div>
            </div>
        </div>

        <!-- Consultoria tecnológica -->
        <div class="modal fade" id="sconsultoria" tabindex="-1" aria-labelledby="appModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header  bg-primary">
                        <h1 class="modal-title fs-5  text-light" id="appModalLabel">Consultoria tecnológica</h1>
                        <button type="button" class="btn text-light fs-3" data-bs-dismiss="modal" aria-label="Close"><i class="fas fa-times"></i></button>
                    </div>
                    <div class="modal-body">
                        <img class="img-fluid" src="assets/img/consultoria.png" alt="">
                    </div>
                </div>
            </div>
        </div>

</template>

<script>
export default {
    name: "ServicioHome"
}
</script>
