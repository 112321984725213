<template>
    <!-- About Start -->
    <div class="container-fluid py-5" id="about">
        <div class="container">
            <div class="row g-5 align-items-center">
                <div class="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                    <div class="about-img">
                        <video class="img-fluid video" autoplay loop muted playsinline>
                            <source src="assets/img/inovacion.mp4" type="video/mp4">
                        </video>
                    </div>
                </div>
                <div class="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                    <div class="btn btn-sm border rounded-pill text-primary px-3 mb-3">Nosotros</div>
                    <h1 class="mb-4">Impulsamos tu crecimiento con soluciones digitales innovadoras.
                    </h1>
                    <p class="mb-4">En Micode, somos un equipo apasionado por la tecnología y la
                        transformación digital. Nos enfocamos en construir relaciones basadas en confianza y resultados,
                        adaptándonos a las necesidades únicas de cada cliente. Nuestro compromiso es ser socios en tu
                        evolución digital, aportando valor y calidad en cada paso del camino.</p>

                    <div class="row g-3">
                        <div class="col-sm-6">
                            <h6 class="mb-3"><i class="fa fa-check text-primary me-2"></i>Innovación constante.</h6>
                            <h6 class="mb-0"><i class="fa fa-check text-primary me-2"></i>Compromiso con la calidad.</h6>
                        </div>
                        <div class="col-sm-6">
                            <h6 class="mb-3"><i class="fa fa-check text-primary me-2"></i>Orientación al cliente.</h6>
                            <h6 class="mb-0"><i class="fa fa-check text-primary me-2"></i>Soluciones personalizadas.</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- About End -->
</template>

<script>
export default {
    name: "NosotrosHome"
}
</script>