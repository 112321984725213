<template>
    <!-- Feature Start -->
    <div class="container-fluid bg-primary feature pt-5">
        <div class="container pt-5">
            <div class="row g-5">
                <div class="col-lg-6 align-self-center mb-md-5 pb-md-5 wow fadeIn" data-wow-delay="0.3s">
                    <div class="btn btn-sm border rounded-pill text-white px-3 mb-3">¿Por qué elegirnos?</div>
                    <h1 class="text-white mb-4">Nuestro enfoque está en ayudar a las empresas a crecer y evolucionar</h1>
                    <p class="text-light mb-4">Nos adaptamos a las necesidades específicas de cada cliente enfocándonos en crear productos eficientes y alineados con sus objetivos comerciales.</p>
                    <div class="d-flex align-items-center text-white mb-3">
                        <div class="btn-sm-square bg-white text-primary rounded-circle me-3">
                            <i class="fa fa-check"></i>
                        </div>
                        <span>Desarrollamos software específico para cada cliente.</span>
                    </div>
                    <div class="d-flex align-items-center text-white mb-3">
                        <div class="btn-sm-square bg-white text-primary rounded-circle me-3">
                            <i class="fa fa-check"></i>
                        </div>
                        <span>Contamos con un equipo especializado y comprometido.</span>
                    </div>
                    <div class="d-flex align-items-center text-white mb-3">
                        <div class="btn-sm-square bg-white text-primary rounded-circle me-3">
                            <i class="fa fa-check"></i>
                        </div>
                        <span>Impulsamos la transformación digital de las empresas.</span>
                    </div>
                    <div class="row g-4 pt-3">
                        <div class="col-sm-6">
                            <div class="d-flex rounded p-3" style="background: rgba(256, 256, 256, 0.1);">
                                <i class="fa fa-users fa-3x text-white"></i>
                                <div class="ms-3">
                                    <h2 class="text-white mb-0">{{ counter2 }}</h2>
                                    <p class="text-white mb-0">Clientes Felices</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="d-flex rounded p-3" style="background: rgba(256, 256, 256, 0.1);">
                                <i class="fa fa-check fa-3x text-white"></i>
                                <div class="ms-3">
                                    <h2 class="text-white mb-0">{{ counter }}</h2>
                                    <p class="text-white mb-0">Proyectos</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 align-self-center mb-5 text-center text-md-end wow fadeIn" data-wow-delay="0.5s">
                    <div class="porque-img">
                        <video class="img-fluid video" autoplay loop muted playsinline>
                            <source src="assets/img/porque.mp4" type="video/mp4">
                        </video>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
    <!-- Feature End -->
</template>

<script>

export default {
    name: "PorqueHome",
    data() {
        return {
            counter: 0, // Inicializamos el contador en 0
            target: 200, // Valor final del contador
            counter2: 0, // Inicializamos el contador en 0
            target2: 150, // Valor final del contador
        };
    },
    mounted() {
        // Iniciamos el contador cuando el componente se monta
        this.startCounter();
    },
    methods: {
        startCounter() {
            const duration = 2000; // Duración total en milisegundos (2 segundos)
            const delay = 10; // Retraso entre cada incremento (en milisegundos)
            const stepTime = Math.ceil(this.target / (duration / delay)); // Tiempo de cada paso
            let currentCount = 0;

            // Creamos un intervalo para actualizar el contador periódicamente
            const interval = setInterval(() => {
                currentCount += stepTime; // Incrementamos el contador por el valor calculado
                if (currentCount >= this.target) {
                    this.counter = this.target; // Al llegar o superar el objetivo, lo establecemos como el valor final
                    this.counter2 = this.target2;
                    clearInterval(interval); // Detenemos el intervalo
                } else {
                    this.counter = currentCount; // Actualizamos el contador en cada paso
                    this.counter2 = currentCount;
                }
            }, delay);
        },
    },
}
</script>

<style scoped>
    @media (max-width: 768px) {
        .img_nosotros {
            display: none;
        }
    }
</style>