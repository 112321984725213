<template>
  <InicioHome />
  <PlanesHome />
  <NosotrosHome />
  <ServicioHome />
  <PorqueHome />
  <FaqsHome />
</template>

<script>
import InicioHome from '@/components/InicioHome.vue';
import NosotrosHome from '@/components/NosotrosHome.vue';
import ServicioHome from '@/components/ServicioHome.vue';
import PorqueHome from '@/components/PorqueHome.vue';
import PlanesHome from '@/components/PlanesHome.vue';
import FaqsHome from '@/components/FaqsHome.vue';


export default {
  name: 'HomeView',
  components: {
    InicioHome,
    NosotrosHome,
    ServicioHome,
    PorqueHome,
    PlanesHome,
    FaqsHome,

  },

}
</script>


<style></style>
