<template>
    <div class="container-fluid pt-5 bg-primary hero-header" id="inicio">
        <br>
        <!-- Login Section -->
        <section class="py-5 py-md-5">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5 col-xxl-4">
                        <div class="card border border-light-subtle rounded-3 shadow wow fadeIn" data-wow-delay="0.1s">
                            <div class="card-body p-3 p-md-3 p-xl-3">
                                <!-- Logo Circular -->
                                <div class="text-center mb-4">
                                    <video src="/assets/img/login_redondo.mp4" class="rounded-circle" style="width: 100px; height: 100px;" autoplay muted loop></video>
                                </div>
                                <form method="post" action="https://micode.cl/administracion/dologin.php" @submit="onSubmit">
                                    <div class="row gy-2 overflow-hidden">
                                        <div class="col-12">
                                            <div class="form-floating mb-3">
                                                <input type="text" name="username" class="form-control" id="username" placeholder="user@example.com" required>
                                                <label for="username">Email</label>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-floating mb-3 position-relative">
                                                <input :type="passwordType" name="password" class="form-control" id="password" placeholder="Password" required>
                                                <label for="password">Password</label>
                                                <span @click="togglePasswordVisibility" class="position-absolute top-50 end-0 translate-middle-y me-3" style="cursor: pointer;">
                                                    <i :class="passwordIcon"></i>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-12 d-flex justify-content-center">
                                            <div class="g-recaptcha" ref="recaptcha" data-size="normal"></div>
                                        </div>
                                        <div class="col-12">
                                            <div class="d-flex gap-2 justify-content-between">
                                                <a href="https://admin.micode.cl/password/reset" target="_blank" class="link-primary text-decoration-none">¿Recuperar contraseña?</a>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="d-grid mt-3">
                                                <button class="btn btn-primary w-100" type="submit" :disabled="!recaptchaResponse || spinner" id="btnIngresar">
                                                    <span v-if="spinner" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                                                    <span v-if="!spinner">Ingresar</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: "PlanesHome",
    data() {
        return {
            spinner: false,
            passwordType: 'password',
            passwordIcon: 'fa fa-eye-slash',
            siteKey: "6LeApnEqAAAAAL3vvE0DcNgCKwHRLEqJFKR7rgSW",
            recaptchaResponse: false,
        };
    },
    methods: {
        onSubmit() {
            this.spinner = true; // Activar el spinner al enviar el formulario
        },
        togglePasswordVisibility() {
            this.passwordType = this.passwordType === 'password' ? 'text' : 'password';
            this.passwordIcon = this.passwordType === 'password' ? 'fa fa-eye-slash' : 'fa fa-eye';
        },
        onRecaptchaSuccess(token) {
            // Actualizar el token de recaptcha
            this.recaptchaResponse = true;
        },
        loadRecaptcha() {
            grecaptcha.render(this.$refs.recaptcha, {
                'sitekey': this.siteKey,
                'callback': this.onRecaptchaSuccess // Configurar el callback en el render
            });
        }
    },
    mounted() {
        //la pagina se vaya al inicio cuando el componente se monta
        window.scrollTo(0, 0);

        // Cargar el script de reCAPTCHA y configurar el callback
        const recaptchaScript = document.createElement('script');
        recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js?render=explicit');
        recaptchaScript.onload = () => {
            grecaptcha.ready(() => {
                this.loadRecaptcha();
            });
        };
        document.head.appendChild(recaptchaScript);

        // Desactivar el spinner cuando la página se carga
        window.addEventListener('load', () => {
            this.spinner = false;
        });
    }
};
</script>

<style scoped>
@media (min-width: 768px) {
    #inicio {
        height: 100vh;
    }
}
.fa-eye, .fa-eye-slash {
    font-size: 1.2rem;
    color: #6c757d;
}

.g-recaptcha {
    transform: scale(1); /* Ajusta el tamaño según tus necesidades */
    transform-origin: 0 0;
    display: inline-block;
}

</style>